import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Button } from "react-bootstrap";
import Hero from "../components/hero";
import PricingCard from "../components/pricing-card";
import PricingCardLarge from "../components/pricing-card-large";
import SingleFAQ from "../components/single-faq";
import Faqs from "../components/faqs";

const FrequesntlyAskedQuestionsPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        heroImg: file(relativePath: { eq: "11.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const siteUrl = data.site.siteMetadata.siteUrl;
  const schemaImage = data.heroImg.publicURL;
  const title = "Frequently Asked Questions";
  const description =
    "FAQ: What happens in a business/executive coaching session? Jason has a structured but flexible agenda for meetings with his small business owner clients.";
  const heroImg = data.heroImg.childImageSharp.fluid;
  const ogImg = `${siteUrl}${schemaImage}`;

  const faqs = [
    {
      question:
        "What happens in a typical business/executive coaching session with Jason?",
      answer: (
        <>
          <p className="d-inline">
            As a potential client of Jason's, you might be wondering what to
            expect from a typical business coaching session. Jason has a
            structured but flexible agenda for meetings with his small business
            owner clients.
          </p>
          <br />
          <br />

          <ol>
            <li>Rapport-Building</li>
            <li>Goal setting and Progress Tracking</li>
            <li>Accountability and Motivation</li>
            <li>Defining the Goal for the Session</li>
            <li>Discussion and Problem-Solving</li>
            <li>Action Points and Timeline</li>
            <li>Feedback and Improvement</li>
            <li>Book next Session!</li>
          </ol>
        </>
      ),
      //   videoLink: "https://youtu.be/7DQfBdSC_Fo",
    },
  ];

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Packages and Prices",
        item: {
          url: `${siteUrl}/frequently-asked-questions`,
          id: `${siteUrl}/frequently-asked-questions`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={title}
        description={description}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/frequently-asked-questions`,
          title: `${title}`,
          description: `${description}`,
          images: [
            {
              url: `${ogImg}`,
              width: 1920,
              height: 1080,
              alt: "Frequently Asked Questions for Jason Cornes Business Coach",
            },
          ],
        }}
      />
      <Hero
        backgroundImage={heroImg}
        title="Frequently Asked Questions"
        isDarken={true}
      />

      <section
        className="py-lg-7 pb-5 pt-7"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <Container>
          <Row>
            {/* <SingleFAQ
              question="What happens in a typical business/executive coaching session with Jason?
"
              response={
                <>
                  <p className="d-inline">
                    As a potential client of Jason's, you might be wondering
                    what to expect from a typical business coaching session.
                    Jason has a structured but flexible agenda for meetings with
                    his small business owner clients.
                  </p>
                  <br />
                  <br />

                  <ol>
                    <li>Rapport-Building</li>
                    <li>Goal setting and Progress Tracking</li>
                    <li>Accountability and Motivation</li>
                    <li>Defining the Goal for the Session</li>
                    <li>Discussion and Problem-Solving</li>
                    <li>Action Points and Timeline</li>
                    <li>Feedback and Improvement</li>
                    <li>Book next Session!</li>
                  </ol>
                </>
              }
              videoLink="https://youtu.be/7DQfBdSC_Fo"
            /> */}
            <Faqs faqs={faqs} />
          </Row>
        </Container>
      </section>

      <section className="py-5 bg-secondary text-white">
        <Container>
          <Row>
            <Col>
              <h2>First Step - Discovery meeting</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                The first step is for us to have a discovery meeting. It will be
                great to meet you and listen about your circumstances, what
                you’d like to achieve and discuss how I can help. I won’t sell
                to you. Instead, I will give you feedback and suggest a way
                forward that I think would be most helpful to you. Please get in
                touch via the contact page, and we’ll get a day and time into
                our diaries.
              </p>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Book Your Discovery Meeting
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};
export default FrequesntlyAskedQuestionsPage;
