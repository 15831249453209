import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { MdPlayArrow } from "@react-icons/all-files/md/MdPlayArrow";
import { Button } from "react-bootstrap";

const Faqs = ({ faqs, hideHeader, bottomNoMargin }) => {
  return (
    <section
      className={` ${bottomNoMargin ? "mt-6" : "my-6"} position-relative w-100`}
    >
      <div
        style={{ zIndex: "-3" }}
        className=" h-100 w-100 position-absolute"
      ></div>
      <Container>
        <Row className={` py-5 ${hideHeader && "d-none"}`}>
          <Col className="text-center">
            <h2 className="underline mb-3 text-primary">
              Frequently Asked Questions
            </h2>
          </Col>
        </Row>
        <Row className="pb-5">
          {faqs.map((faq) => (
            <Col xs={12} className="my-1 mx-0 px-0 ">
              <Accordion>
                <Card className="border-0">
                  <Card.Header className="border-0 px-0 p-0 m-0">
                    <ContextAwareToggle eventKey="0" className="border-0 ">
                      <p className="pb-0 mb-0" style={{ fontWeight: "600" }}>
                        {faq.question}
                      </p>
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="pt-0"
                      style={{ backgroundColor: "#F5F5F5" }}
                    >
                      <Row>
                        <Col
                          className="ps-1 fw-light"
                          xs={{ span: 11, offset: 1 }}
                        >
                          {faq.answer}
                        </Col>
                        <Col xs={{ span: 11, offset: 1 }}>
                          {" "}
                          {faq.videoLink && (
                            <Button
                              variant="primary"
                              size="lg"
                              className="cta-btn-gtm d-none d-md-inline-block"
                              href={faq.videoLink}
                            >
                              Watch Jason's video on YouTube &#8594;
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <hr className="bg-primary" />
              </Accordion>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const [turnArrow, setTurnArrow] = useState(false);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey);
    setTurnArrow(!turnArrow);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="border-0"
      style={{
        color: isCurrentEventKey ? "#feedf8" : "black",
        // backgroundColor: isCurrentEventKey ? "black" : "#feedf8",
        backgroundColor: "#F5F5F5",
      }}
      onClick={decoratedOnClick}
    >
      <Container className="pe-0 ps-1" fluid={true}>
        <Row className="align-items-center">
          <Col xs={1} className="d-flex px-0  justify-content-start">
            <MdPlayArrow
              className={`position-relative fs-5 text-primary cheveron  ${
                turnArrow ? "cheveron-down" : ""
              }`}
            />
          </Col>
          <Col
            className="d-flex justify-content-start align-items-center ps-0"
            xs={11}
          >
            {children}
          </Col>
        </Row>
      </Container>
    </Card.Header>
  );
}
