import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { GoArrowRight } from "react-icons/go";
import { Link } from "gatsby";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const PricingCard = ({ title, subText, price, features, mostPop }) => {
	return (
		<Col className="mb-6 mb-xl-0" md={6} xl={4}>
			<div
				className="price-box p-4 py-5 position-relative
            "
			>
				{mostPop === true && (
					<div
						style={{
							top: "0",
							left: "50%",
							transform: "translate(-50%, -50%)",
							borderRadius: "5px",
						}}
						className="w-50 position-absolute text-center py-2 bg-secondary"
					>
						<p className="p-0 m-0 text-white">Most Popular</p>
					</div>
				)}
				<Row>
					<Col>
						<h4 style={{ fontSize: "1.4rem" }} className="text-primary">
							{title}
						</h4>
						<p style={{ fontSize: "90%", fontWeight: "light" }}>{subText}</p>
						<p style={{ fontSize: "90%", fontWeight: "light" }}>{price}</p>
						<hr
							className="bg-secondary my-4"
							style={{ height: "2px", opacity: 1 }}
						/>
						<div className="features-box">
							{features.map((feature) => {
								return (
									<div className="d-flex align-items-center pt-3 position-relative ">
										<div
											className="mr-3 d-flex align-items-center p-0 justify-content-center"
											style={{
												borderRadius: "100%",
												height: "22px",
												minWidth: "22px",
												backgroundColor: "#000000",
											}}
										>
											<GoArrowRight
												className="m-0 p-0 "
												style={{
													height: "15px",
													width: "15px",
													color: "#000000",
													backgroundColor: "white",
													borderRadius: "100%",
													borderWidth: "1px",
												}}
											/>
										</div>
										<p
											style={{ fontSize: "90%", fontWeight: "light" }}
											className="pb-0 mb-0"
										>
											{feature}
										</p>
									</div>
								);
							})}
						</div>
						<hr
							className="bg-secondary my-4"
							style={{ height: "2px", opacity: 1 }}
						/>
						{mostPop !== true && (
							<Button
								variant="primary"
								as={Link}
								to="/contact-us"
								className=" w-100 py-3"
							>
								Book Your Discovery Meeting
							</Button>
						)}
						{mostPop === true && (
							<Button
								variant="secondary"
								as={Link}
								to="/contact-us"
								className=" w-100 py-3"
							>
								Book Your Discovery Meeting
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</Col>
	);
};

export default PricingCard;
