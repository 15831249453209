import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { GoArrowRight } from "react-icons/go";
import { Link } from "gatsby";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FaDigg } from "react-icons/fa";

const SingleFAQ = ({ question, response, videoLink }) => {
  return (
    <Col>
      <p className="fw-bold"> Question: {question}</p>
      <p className="d-inline">Answer: </p>
      {response}
      <p>
        You can read more in Jason’s blog here: Unlocking Success. A Coaching
        session with Jason
      </p>
      {videoLink && (
        <>
          <p>
            You can see Jason’s video here: <a href={videoLink}>{videoLink}</a>
          </p>
          <Button
            variant="primary"
            size="lg"
            className="cta-btn-gtm d-none d-md-inline-block"
            href={videoLink}
          >
            Watch Jason's video on YouTube &#8594;
          </Button>
        </>
      )}
    </Col>
  );
};

export default SingleFAQ;
